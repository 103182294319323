import "normalize.css";
import "./main.css";

// const navbar = document.querySelector(".nav-bar-main");
// const falseNavBar = document.querySelector(".nav-bar-home");
// const topButton = document.querySelector("#top-button");
// const topButtonContent = document.querySelector("#top-button-content")
// const logoHack = document.querySelector("#logo-hack");
// const smallWindowWidth = 639;

// let sideNavShow = false;

// if (window.innerWidth <= smallWindowWidth) {
//   navbar.classList.add("nav-sticky");
// }

// window.onorientationchange = function() {  	window.location.reload(); }; 

// // When the user scrolls the page, execute myFunction
// window.onscroll = function() {
//   stickyNav();
// };

// // Adding toggle nav to button
// topButton.addEventListener("click", toggleSideNav);

// console.log(document.querySelectorAll(".dismiss-nav"));
// // Adding toggle nav feature on click of any side nav element
// for (const elem of document.querySelectorAll(".dismiss-nav")) {
//   elem.addEventListener("click", toggleSideNav);
// }

// // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
// function stickyNav() {

//   if (window.pageYOffset >= 1) {
//     navbar.classList.add("nav-sticky");
//     falseNavBar.style.display = 'none';
//     // if (window.innerWidth > smallWindowWidth) {
//     //   logoHack.style.visibility = 'hidden';
//     // }
    
//   } else {
//     if (window.innerWidth > smallWindowWidth) {
//       navbar.classList.remove("nav-sticky");
//     }
//     falseNavBar.style.display = 'flex';
//     // logoHack.style.visibility = 'visible';
//   }
// }

// // Add the side nav show reveal
// function toggleSideNav() {
//   if (sideNavShow) {
//     topButtonContent.classList.add("fa-bars");
//     topButtonContent.classList.remove("fa-times");
//     navbar.classList.remove("side-nav-bar");

//     sideNavShow = false;
//   } else {
//     topButtonContent.classList.add("fa-times");
//     topButtonContent.classList.remove("fa-bars");
//     navbar.classList.add("side-nav-bar");

//     sideNavShow = true;
//   }
// }

// // When the user clicks anywhere outside of the modal, close it
// // Also hack fix to stop video from playing when clicked outside
// const modal = document.getElementById('watchVideo');
// let videoInModal = document.getElementById('modalVideo');
// const initialVideoSrc = videoInModal.src;

// window.onclick = function(event) {
//   if (event.target == modal) {
//     modal.style.display = "none";
//     videoInModal.src = '';
//     if (window.innerWidth <= smallWindowWidth) {
//       navbar.style.display = 'flex';
//     }
//   }
// }

// //This will re-add the src
// // const modalVideoButton = document.getElementById('modalVideoButton');
// // modalVideoButton.addEventListener("click", addVideoSrc);

// function addVideoSrc() {
//   videoInModal.src = initialVideoSrc;
//   if (window.innerWidth <= smallWindowWidth) {
//     navbar.style.display = 'none';
//   }
// }

// //Similar behaviour to clicking out of modal
// const closeVideoButton = document.getElementById('closeVideoButton');
// closeVideoButton.addEventListener("click", stopVideo);

// function stopVideo() {
//   videoInModal.src = '';
//   if (window.innerWidth <= smallWindowWidth) {
//     navbar.style.display = 'flex';
//   }
// }

// // particlesJS.load(@dom-id, @path-json, @callback (optional));
// window.addEventListener('DOMContentLoaded', (event) => {
//   particlesJS("particles-js", {
//     "particles": {
//       "number": {
//         "value": 144,
//         "density": {
//           "enable": true,
//           "value_area": 800
//         }
//       },
//       "color": {
//         "value": "#00d4ff"
//       },
//       "shape": {
//         "type": "edge",
//         "stroke": {
//           "width": 0,
//           "color": "#000000"
//         },
//         "polygon": {
//           "nb_sides": 4
//         },
//         "image": {
//           "src": "img/github.svg",
//           "width": 100,
//           "height": 100
//         }
//       },
//       "opacity": {
//         "value": 0.16024100246771156,
//         "random": true,
//         "anim": {
//           "enable": false,
//           "speed": 1,
//           "opacity_min": 0.1,
//           "sync": false
//         }
//       },
//       "size": {
//         "value": 44.066275678620684,
//         "random": true,
//         "anim": {
//           "enable": true,
//           "speed": 19.476567255021315,
//           "size_min": 1.6230472712517763,
//           "sync": false
//         }
//       },
//       "line_linked": {
//         "enable": false,
//         "distance": 256.38560394833854,
//         "color": "#36c3df",
//         "opacity": 0.3685543056757366,
//         "width": 0.9614460148062693
//       },
//       "move": {
//         "enable": true,
//         "speed": 3.2048200493542316,
//         "direction": "top-right",
//         "random": false,
//         "straight": false,
//         "out_mode": "out",
//         "bounce": false,
//         "attract": {
//           "enable": false,
//           "rotateX": 600,
//           "rotateY": 1200
//         }
//       }
//     },
//     "interactivity": {
//       "detect_on": "canvas",
//       "events": {
//         "onhover": {
//           "enable": true,
//           "mode": "bubble"
//         },
//         "onclick": {
//           "enable": false,
//           "mode": "repulse"
//         },
//         "resize": true
//       },
//       "modes": {
//         "grab": {
//           "distance": 400,
//           "line_linked": {
//             "opacity": 1
//           }
//         },
//         "bubble": {
//           "distance": 219.1113816189898,
//           "size": 40.57618178129441,
//           "duration": 0.8115236356258881,
//           "opacity": 0.24345709068776647,
//           "speed": 3
//         },
//         "repulse": {
//           "distance": 200,
//           "duration": 0.4
//         },
//         "push": {
//           "particles_nb": 4
//         },
//         "remove": {
//           "particles_nb": 2
//         }
//       }
//     },
//     "retina_detect": true
//   });
// });

// window.addEventListener('DOMContentLoaded', (event) => {
//   particlesJS("particlesjs", {
//     "particles": {
//       "number": {
//         "value": 144,
//         "density": {
//           "enable": true,
//           "value_area": 800
//         }
//       },
//       "color": {
//         "value": "#00d4ff"
//       },
//       "shape": {
//         "type": "edge",
//         "stroke": {
//           "width": 0,
//           "color": "#000000"
//         },
//         "polygon": {
//           "nb_sides": 4
//         },
//         "image": {
//           "src": "img/github.svg",
//           "width": 100,
//           "height": 100
//         }
//       },
//       "opacity": {
//         "value": 0.16024100246771156,
//         "random": true,
//         "anim": {
//           "enable": false,
//           "speed": 1,
//           "opacity_min": 0.1,
//           "sync": false
//         }
//       },
//       "size": {
//         "value": 44.066275678620684,
//         "random": true,
//         "anim": {
//           "enable": true,
//           "speed": 19.476567255021315,
//           "size_min": 1.6230472712517763,
//           "sync": false
//         }
//       },
//       "line_linked": {
//         "enable": false,
//         "distance": 256.38560394833854,
//         "color": "#36c3df",
//         "opacity": 0.3685543056757366,
//         "width": 0.9614460148062693
//       },
//       "move": {
//         "enable": true,
//         "speed": 3.2048200493542316,
//         "direction": "top-right",
//         "random": false,
//         "straight": false,
//         "out_mode": "out",
//         "bounce": false,
//         "attract": {
//           "enable": false,
//           "rotateX": 600,
//           "rotateY": 1200
//         }
//       }
//     },
//     "interactivity": {
//       "detect_on": "canvas",
//       "events": {
//         "onhover": {
//           "enable": true,
//           "mode": "bubble"
//         },
//         "onclick": {
//           "enable": false,
//           "mode": "repulse"
//         },
//         "resize": true
//       },
//       "modes": {
//         "grab": {
//           "distance": 400,
//           "line_linked": {
//             "opacity": 1
//           }
//         },
//         "bubble": {
//           "distance": 219.1113816189898,
//           "size": 40.57618178129441,
//           "duration": 0.8115236356258881,
//           "opacity": 0.24345709068776647,
//           "speed": 3
//         },
//         "repulse": {
//           "distance": 200,
//           "duration": 0.4
//         },
//         "push": {
//           "particles_nb": 4
//         },
//         "remove": {
//           "particles_nb": 2
//         }
//       }
//     },
//     "retina_detect": true
//   });
// });